<template>
    <div class="loader-wrapper" v-show="isLoading">
        <loader></loader>
    </div>
</template>

<script>
import Loader from "@/components/basic/Loader.vue"
import { renew } from "@/services/intratone/auth.js"
import httpIntratone from "@/services/httpIntratone.js"
import store from "@/store"

import { useToast } from "vue-toastification"
const toast = useToast()

export default {
    name: "Renew",
    components: {
        Loader,
    },
    data() {
        return {
            // Show loader
            isLoading: true,
        }
    },
    methods: {
        async renew(dataURI, from) {
            try {
                // Requesting API for logout
                let responseRenew = await renew()

                if (responseRenew === false || responseRenew.data.error) {
                    throw new Error(this.$t("errors.0"))
                }

                // Storing new user state
                store.commit("auth/setJwtValues", {
                    jwt: responseRenew.data.jwt,
                    jwtRenew: responseRenew.data.renew.jwt,
                    expireAt: responseRenew.data.expire_gmt_at,
                    renewExpireAt: responseRenew.data.renew.expire_gmt_at,
                })
                store.commit("auth/setLogged", true)
                localStorage.setItem("apiintra_jwt", responseRenew.data.jwt)
                localStorage.setItem(
                    "apiintra_jwt_expire",
                    responseRenew.data.expire_gmt_at
                )
                localStorage.setItem(
                    "apiintra_renew_expire",
                    responseRenew.data.renew.expire_gmt_at
                )
                // Setting new user state v1 side
                let responseV1 = await httpIntratone.post(
                    "renew.php",
                    `jwt=${responseRenew.data.jwt}&jwtRenew=${responseRenew.data.renew.jwt}&expireAt=${responseRenew.data.expire_at}&renewExpireAt=${responseRenew.data.renew.expire_at}&SID=${store.getters["auth/getIntratoneDev2SID"]}`
                )

                if (responseV1.status == "200") {
                    // on rappelle la fonction V1 en paramètre
                    if (dataURI !== null && dataURI !== "undefined") {
                        let dts = JSON.parse(dataURI)
                        if (dts.zone === "ZoneDonnee") {
                            window.DataToScreen(
                                dts.php,
                                dts.xslt,
                                dts.data,
                                dts.zone,
                                dts.direct,
                                dts.flagcharg,
                                dts.debug
                            )
                        } else {
                            history.back()
                        }
                    } else {
                        if (from !== "") {
                            this.$router.replace({
                                path: from,
                            })
                        } else {
                            history.back()
                        }
                    }
                } else {
                    this.$router.push({
                        name: "Logout",
                        query: {
                            call: 0,
                        },
                    })
                }
            } catch (error) {
                console.log(error)
                this.$router.push({
                    name: "Logout",
                    query: {
                        call: 0,
                    },
                })
            }
        },
    },
    beforeMount() {
        let data = null
        let from = ""
        let back_button = false
        if (this.$route.query.params !== "") {
            data = decodeURIComponent(this.$route.query.params)
        }
        if (this.$route.query.fromRoute !== "") {
            from = this.$route.query.fromRoute
        }
        if (this.$route.query.backButton === 1) {
            back_button = true
        }
        this.renew(data, from)
    },
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
